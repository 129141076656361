import React, { useState, useMemo } from "react";
import {
  TShiftPopupAction,
  TDecoratedOpenShift,
} from "src/components/scheduler/scheduler.types";
import EditOpenShiftForm from "../shift_form/EditOpenShiftForm";
import { PopupCard } from "src/components/common/popup";
import ShiftDetails from "../shift/ShiftDetails";
import { CardHeader, CardBody } from "reactstrap";
import EventPopupHeader from "src/components/scheduler/components/event_popup/EventPopupHeader";
import DeleteOpenShift from "./DeleteOpenShift";
import { TMomentRange } from "src/lib/rangeHandler";
import AddOpenShiftForm from "../shift_form/AddOpenShiftForm";

interface IProps {
  event: TDecoratedOpenShift;
  initialView: TShiftPopupAction;
  editValues?: TMomentRange;
  close: () => void;
}

const styles = {
  popup: {
    width: "500px",
  },
};

const getTitle = (view: TShiftPopupAction) => {
  switch (view) {
    case "edit":
      return "Edit open shift";
    case "delete":
      return "Delete open shift";
    case "read":
      return "Open shift";
    case "clone":
      return "Clone open shift";
  }
};

const actions: TShiftPopupAction[] = ["edit", "delete", "read", "clone"];

// optimization so that this component only re-renders if
// relevant props have changed
const areEqual = (prev: IProps, next: IProps) =>
  prev.event === next.event && prev.close === next.close;

const ShiftPopup = React.memo((props) => {
  const { event, close, initialView, editValues } = props;

  const [view, setView] = useState<TShiftPopupAction>(initialView);

  const notifyGroups = useMemo(() => event.data.groups.map((g) => g.id), [
    event.data.groups,
  ]);

  return (
    <PopupCard style={styles.popup}>
      <CardHeader>
        <EventPopupHeader
          view={view}
          setView={setView}
          availableActions={actions}
          handleClose={close}
          title={getTitle(view)}
        />
      </CardHeader>
      <CardBody>
        {view === "read" && (
          <ShiftDetails
            start={event.start}
            end={event.end}
            role={event.data.role}
            assignee="None"
          />
        )}

        {view === "edit" && (
          <EditOpenShiftForm
            role={event.data.role}
            notifyGroups={notifyGroups}
            reason={event.data.reason}
            start={editValues?.start || event.start}
            end={editValues?.end || event.end}
            eventId={event.id}
            handleClose={close}
          />
        )}

        {view === "clone" && (
          <AddOpenShiftForm
            role={event.data.role}
            notifyGroups={notifyGroups}
            reason={event.data.reason}
            start={editValues?.start || event.start}
            end={editValues?.end || event.end}
            handleClose={close}
          />
        )}

        {view === "delete" && (
          <DeleteOpenShift eventId={event.id} handleClose={close} />
        )}
      </CardBody>
    </PopupCard>
  );
}, areEqual);

export default ShiftPopup;
