import React from "react";
import { useEventPosition, SchedulerTypes } from "../schedulerTable";

interface IProps {
  event: SchedulerTypes.IDecoratedEvent;
  children: React.ReactNode;
}

const StaticEvent: React.FC<IProps> = (props) => {
  const { event, children } = props;
  const eventPosition = useEventPosition(event);

  return <div style={eventPosition}>{children}</div>;
};

export default StaticEvent;
