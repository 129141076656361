import React, { useCallback } from "react";
import { ListGroup, CardBody } from "reactstrap";
import { PopupCard } from "src/components/common/popup";
import DropdownListItem from "src/components/common/dropdown-list/DropdownListItem";
import { removeTokenCookie, removeV1TokenCookie } from "src/lib/token";
import { removeBetaCookie } from "src/lib/cookies";

const v1App = process.env.REACT_APP_NODE_API_URL;

const AccountDropdownPopup = () => {
  const logout = useCallback(() => {
    removeTokenCookie();
    removeV1TokenCookie();
    window.location.assign(`${v1App}`);
  }, []);

  const backToOldVersion = useCallback(() => {
    removeBetaCookie();
    window.location.assign(`${v1App}/schedules/team-calendar`);
  }, []);

  return (
    <PopupCard
      style={{
        maxHeight: "250px",
        width: "300px",
        overflowY: "auto",
      }}
    >
      <CardBody className="px-0 py-2">
        <ListGroup flush>
          <DropdownListItem
            onClick={() => window.location.assign(`${v1App}/settings`)}
          >
            Settings
          </DropdownListItem>
          <DropdownListItem onClick={logout}>Sign Out</DropdownListItem>
          <DropdownListItem onClick={backToOldVersion}>
            Switch back to old Calendar
          </DropdownListItem>
        </ListGroup>
      </CardBody>
    </PopupCard>
  );
};

export default AccountDropdownPopup;
