import React, { useState, useCallback } from "react";
import StatefulButton from "src/components/common/stateful-button";
import { useStoreActions, useStoreState } from "src/store";
import { TDecoratedDraftShift } from "../../scheduler.types";
import { evaluateDraftWorkshift } from "src/api";
import { errorIsViolation } from "src/lib/violations";
import { AxiosError } from "axios";
import { TShiftViolationErrorData } from "src/api/api.types";
import ViolationMessage from "../violation/ViolationMessage";

interface IProps {
  event: TDecoratedDraftShift;
  handleClose: () => void;
}

const DeleteDraftShift: React.FC<IProps> = (props) => {
  const { event, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const locationId = useStoreState((state) => state.location.locationId);
  const events = useStoreState((state) => state.events.events);

  const view = useStoreState((state) => state.scheduler.view);
  const employeeRecordsById = useStoreState(
    (state) => state.employees.employeeRecordsById
  );
  const [showViolations, setShowViolations] = useState(false);
  const [violationMessage, setViolationMessage] = useState("");

  const deleteDraftShift = useStoreActions(
    (actions) => actions.draft.deleteDraftShift
  );

  const handleDelete = useCallback(async () => {
    setLoading(true);
    const employee = employeeRecordsById[event.resourceId];
    try {
      const runValidation = !showViolations;
      if (runValidation) {
        const shift = {
          start_time: event.start.format("YYYY-MM-DD HH:mm:ss"),
          end_time: event.end.format("YYYY-MM-DD HH:mm:ss"),
        };
        const shifts = events
          .filter((e) => e.type === "draft_shift" || e.type === "shift")
          // this filters to employees shifts and filters
          // out the shift we are deleting.
          .filter(
            (shift) =>
              shift.resourceId === event.resourceId && shift.id !== event.id
          )
          .map((shift) => ({
            start_time: shift.start.format("YYYY-MM-DD HH:mm:ss"),
            end_time: shift.end.format("YYYY-MM-DD HH:mm:ss"),
          }));
        await evaluateDraftWorkshift({
          user_id: employee.userId ? +employee.userId : -1,
          employee_id: employee.uid,
          location_id: locationId,
          current_shift: shift,
          time_interval: view,
          shifts: [...shifts],
        });
        setViolationMessage("");
      }
      await deleteDraftShift({
        eventId: event.id,
        employeeUid: employee.uid,
        violation: violationMessage,
      });
      handleClose();
    } catch (err) {
      if (errorIsViolation(err)) {
        setShowViolations(true);
        const error = err as AxiosError<TShiftViolationErrorData>;
        setViolationMessage(error.response?.data.meta.detail || "");
      }
    } finally {
      setLoading(false);
    }
  }, [
    employeeRecordsById,
    event,
    showViolations,
    deleteDraftShift,
    handleClose,
    events,
    locationId,
    view,
    violationMessage,
  ]);

  return (
    <>
      {showViolations ? (
        <ViolationMessage
          title="Encountered violations"
          details={violationMessage}
          description="The following violations occurred for this user when trying to delete this shift:"
          subtext="To ignore this warning, you can proceed below."
        />
      ) : (
        <p>Are you sure you want to delete this draft workshift?</p>
      )}

      <div className="d-flex align-items-center justify-content-between mt-2">
        <StatefulButton
          color="danger"
          onClick={handleDelete}
          className="ml-auto"
          loading={loading}
        >
          {showViolations ? "Proceed" : "Delete"}
        </StatefulButton>
      </div>
    </>
  );
};

export default DeleteDraftShift;
