import { Moment } from "moment";

const generateColumns = (
  startTime: Moment,
  count: number,
  incrementer: (prev: Moment) => Moment
) => {
  let lastTime = startTime;
  const newColumns = [{ time: startTime }];
  for (let i = 0; i < count - 1; i++) {
    const newTime = incrementer(lastTime);

    newColumns.push({ time: newTime });
    lastTime = newTime;
  }

  return newColumns;
};

export default generateColumns;
