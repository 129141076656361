import { useMemo } from "react";
import { IDecoratedEvent } from "../types";
import { useStoreState } from "src/store";

const useEventPosition = (event: IDecoratedEvent) => {
  const eventHeight = useStoreState(
    (state) => state.scheduler.layout.eventHeight
  );
  const cellPaddingTop = useStoreState(
    (state) => state.scheduler.layout.cellPaddingTop
  );
  const mainWidth = useStoreState((state) => state.scheduler.tableMainWidth);
  const range = useStoreState((state) => state.scheduler.range);

  const style = useMemo(() => {
    const r = range.end.valueOf() - range.start.valueOf();
    const x1 = event.posStart.valueOf() - range.start.valueOf();
    const x2 = event.posEnd.valueOf() - range.start.valueOf();
    const left = (x1 / r) * mainWidth;
    const right = (x2 / r) * mainWidth;
    const width = right - left;

    return {
      position: "absolute" as "absolute",
      top: cellPaddingTop + eventHeight * event.group,
      left,
      width,
      height: eventHeight,
    };
  }, [mainWidth, event, range, eventHeight, cellPaddingTop]);

  return style;
};

export default useEventPosition;
