import React from "react";
import styles from "./index.module.scss";
import { SchedulerTypes } from "../schedulerTable";
import EmployeeResource from "./EmployeeResource";

interface IProps {
  row: SchedulerTypes.IRow;
}

const Resource: React.FC<IProps> = (props) => {
  const { row } = props;

  if (row.id === "open") {
    return <div className={styles.Resource}>Open shifts</div>;
  }

  return <EmployeeResource row={row} />;
};

export default Resource;
