import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { StoreProvider } from "easy-peasy";
import { store } from "./store";
import Router from "./Router";

const App = () => {
  useEffect(() => {
    const intercom = (window as any).Intercom;
    intercom("boot", {
      // eslint-disable-next-line
      app_id: "mwocdgug",
    });

    return () => {
      intercom("hide");
    };
  }, []);

  return (
    <StoreProvider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Router />
      </DndProvider>
    </StoreProvider>
  );
};

export default App;
